import { WriskTheme } from '@wrisk/ui-components'

import logo from './assets/icons/logo.svg'
import miniDriveaway from './assets/images/cropped-mini-driveaway-hero.jpg'
import miniFlex from './assets/images/cropped-mini-flex-hero.jpg'
import MINIELEC12M2024H2Foreground from './assets/images/promo/MINIELEC12M2024H2/foreground.png'
import MININEW12M2023H2Background from './assets/images/promo/MININEW12M2023H2/background.png'
import MININEW12M2023H2Foreground from './assets/images/promo/MININEW12M2023H2/foreground.png'
import MININEW12M2023Q1Background from './assets/images/promo/MININEW12M2023Q1/background.png'
import MININEW12M2023Q1Foreground from './assets/images/promo/MININEW12M2023Q1/foreground.png'
import MININEW12M2023Q2Background from './assets/images/promo/MININEW12M2023Q2/background.png'
import MININEW12M2023Q2Foreground from './assets/images/promo/MININEW12M2023Q2/foreground.png'
import fontFaces from './fonts'
import icons from './icons'
import illus from './illus'

const typoSizes = {
  xxl: {
    fontSize: ['30px', '30px', '40px', '50px'],
    lineHeight: ['30px', '30px', '40px', '50px'],
  },
  xl: {
    fontSize: ['22px', '24px', '28px', '32px'],
    lineHeight: ['26px', '28px', '30px', '32px'],
  },
  lg: {
    fontSize: ['20px', '20px', '22px', '24px'],
    lineHeight: ['24px', '24px', '26px', '28px'],
  },
  md: {
    fontSize: ['18px', '18px', '20px'],
    lineHeight: ['22px', '22px', '24px'],
  },
  base: {
    fontSize: ['16px', '16px', '16px', '18px'],
    lineHeight: ['22px', '22px', '22px', '24px'],
  },
  sm: {
    fontSize: ['14px', '14px', '14px', '16px'],
    lineHeight: ['20px', '20px', '20px', '22px'],
  },
  xs: {
    fontSize: ['12px', '12px', '12px', '14px'],
    lineHeight: ['18px', '18px', '18px', '20px'],
  },
}

export const miniTheme: WriskTheme = {
  // Common
  name: 'MINI',
  illus,
  fontFaces,
  icons,
  logo: {
    regular: logo,
  },
  breakpoints: ['480px', '768px', '1024px', '1200px', '1600px'],
  space: [
    '0rem', //  0    0px
    '0.25rem', //  1    4px
    '0.5rem', //  2    8px
    '0.75rem', //  3   12px
    '1.0rem', //  4   16px
    '1.25rem', //  5   20px
    '1.5rem', //  6   24px
    '1.75rem', //  7   28px
    '2.0rem', //  8   32px
    '2.5rem', //  9   40px
    '3.0rem', // 10   48px
    '3.5rem', // 11   56px
    '4.0rem', // 12   64px
    '4.5rem', // 13   72px
    '5.0rem', // 14   80px
  ],
  fonts: {
    body: `'Mini Serif', serif`,
    header: `'Mini Serif', serif`,
    navbar: `'Mini Sans', sans-serif`,
    buttons: `'Mini Sans', sans-serif`,
    input: `'Mini Sans', sans-serif`,
    licencePlate: `'UKLicencePlate', sans-serif`,
  },
  lineHeights: ['1em', '1.1em', '1.2em', '1.3em', '1.4em', '1.5em'],
  radii: [0, 0, 0, 0],
  typoSizes,
  navigation: {
    height: ['58px'],
  },
  transitions: {
    default: 'all 250ms ease',
  },
  shadows: {
    default: 'none',
    hover: 'none',
    input: '0 0 0 2px #000',
    inset: 'inset -8px 0 0 0 #fff, inset 8px 0 0 0 #fff',
    popupMenu: '0 4px 32px 0 rgba(0, 0, 0, 0.06)',
    radioNested: 'inset 0 0 0 1px #000000',
    radioStandalone: 'none',
    radioHover: 'inset 0 0 0 1px #000000',
    radioChecked: 'inset 0 0 0 2px #000000',
  },
  colors: {
    chalk: '#ffffff',
    background: '#f1f1f1',
    body: '#000000',
    bodySecondary: '#000000',
    hyperlink: '#000000',
    focus: '#000000',
    hover: '#f7f7f7',

    primaryAccent: '#000000',
    secondaryAccent: '#000000',

    foregroundPromoBanner: '#fff',
    backgroundPromoBanner: '#333',

    foregroundPromoActivated: '#fff',
    backgroundPromoActivated: '#1e9771',

    textOnNavigation: '#000000',
    surfaceNavigation: '#ffffff',

    textInfo: '#1C69D4',
    surfaceInfoSubdued: '#1C69D41A',
    surfaceInfo: '#1C69D4',

    textCritical: '#e42130',
    textOnCritical: '#fff',
    surfaceCritical: '#e42130',
    surfaceCriticalHovered: '#991620',
    surfaceCriticalSubdued: '#e421301a',

    textWarning: '#000000',
    textOnWarning: '#000000',
    surfaceWarning: '#e1ca00',
    surfaceWarningSubdued: '#e1ca001a',

    textHighlight: '#000000',
    textOnHighlight: '#000000',
    surfaceHighlight: '#e5e5e5',
    surfaceHighlightSubdued: '#0000001a',

    textSuccess: '#1e9771',
    textOnSuccess: '#fff',
    surfaceSuccess: '#1e9771',
    surfaceSuccessSubdued: '#1e97711a',

    border: '#000000',
    divider: '#0000000d',
    inactive: '#00000059',
    placeholder: '#00000059',

    textDisabled: '#000000',
    surfaceDisabled: '#0000000d',

    textFooter: '#ffffff',
    dividerFooter: '#ffffff33',
    surfaceFooter: '#000000',

    radioCheckedBackground: '#000000',
    radioCheckedForeground: '#ffffff',

    dragBoxBorderActive: '#4F46E5',
    dragBoxBorderDefault: '#4B5563',

    progressBackgroundColor: '#9CA3AF',
    progressBarColor: '#4F46E5',
  },
  proposalImages: {
    'mini-flex': miniFlex as string,
    'mini-driveaway': miniDriveaway as string,
  },
  promoImages: {
    MININEW12M2023Q1: {
      foreground: MININEW12M2023Q1Foreground as string,
      background: MININEW12M2023Q1Background as string,
    },
    MININEW12M2023Q2: {
      foreground: MININEW12M2023Q2Foreground as string,
      background: MININEW12M2023Q2Background as string,
    },
    MININEW12M2023H2: {
      foreground: MININEW12M2023H2Foreground as string,
      background: MININEW12M2023H2Background as string,
    },
    MININEW12M2024Q1: {
      foreground: MININEW12M2023H2Foreground as string,
      background: MININEW12M2023H2Background as string,
    },
    MINIELEC12M2024H2: {
      foreground: MINIELEC12M2024H2Foreground as string,
      background: MININEW12M2023H2Background as string,
    },
  },
  // Components
  Typo: {
    default: {},
    heading: {
      fontFamily: 'header',
      textTransform: 'uppercase',
    },
    buttons: {
      fontFamily: 'buttons',
      textTransform: 'uppercase',
    },
  },
  Heading: {
    default: {
      textTransform: 'uppercase',
    },
    h1: {
      textTransform: 'uppercase',
      '&::after': {
        content: '""',
        display: 'block',
        left: 0,
        backgroundColor: 'body',
        width: '80px',
        height: '4px',
        marginTop: 3,
      },
    },
    h2: {
      textTransform: 'uppercase',
      fontWeight: 'normal',
      '&::after': {
        content: '""',
        display: 'block',
        left: 0,
        backgroundColor: 'body',
        width: '60px',
        height: '2px',
        marginTop: 1,
      },
    },
    h3: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    h4: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
  },
  Logo: {
    default: {
      display: 'block',
      height: '33px',
    },
  },
  AppName: {
    default: {
      pl: 6,
      ...typoSizes.xs,
      textTransform: 'uppercase',
    },
  },
  FooterAnchor: {
    default: {
      ...typoSizes.xs,
      textTransform: 'uppercase',
    },
  },
  NavAnchor: {
    default: {
      ...typoSizes.xs,
      textTransform: 'uppercase',
      height: '100%',
      borderRadius: '0',
    },
    inverted: {},
  },
  Button: {
    base: {
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: '0.9375rem',
    },
    layout: {
      default: {
        px: '30px',
        py: '10px',
      },
      fixed: {
        px: '30px',
        py: '10px',
        width: '85%',
        maxWidth: '262px',
      },
      small: {
        px: 4,
        py: 2,
        fontSize: '0.85rem',
        lineHeight: '1.5em',
      },
    },
  },
  SecondaryButton: {
    variants: {
      default: {
        boxShadow: 'inset 0 0 0 2px #000000',
      },
      inverted: {},
    },
  },
}
